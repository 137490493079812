import { VuesticPlugin } from "vuestic-ui";
import { createApp, ref } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuesticGlobalConfig from "./services/vuestic-ui/global-config";
import { authClient, authInstaller } from "./services/auth";
import toasts from "./services/toasts";
import './assets/css/vuestic.css';
import TokenExpModal from './components/modal/TokenExpierdModal.vue'; // Import the modal component

const reconnectModalVisible = ref(false);

// i18n
const i18nConfig = {
  locale: "en",
  fallbackLocale: "en",
  messages: {
    en: require("@/i18n/en.json")
  }
};
const i18n = createI18n(i18nConfig);

authClient.initialize()
  .then(() => {
    const app = createApp(App);

    const handleSessionExpiry = () => {
      return new Promise<void>((resolve) => {
        reconnectModalVisible.value = true;

        const reconnect = async () => {
          reconnectModalVisible.value = false;

          try {
            await authClient.logout(); 
            resolve();
          } catch (error) {
            console.error("Reconnection failed:", error);
          }
        };

        // Show modal and handle reconnection
        const modalContainer = document.createElement('div');
        document.body.appendChild(modalContainer);

        const modalApp = createApp(TokenExpModal, {
          isOpen: true,
          title: 'Session Expired',
          content: 'Your session has expired. Click here to reconnect.',
          confirmText: 'Reconnect',
          onConfirm: reconnect
        });

        modalApp.mount(modalContainer);
      });
    };

    const handleError = async (err: Error) => {
      if (!err || !err.message) {
        app.config.globalProperties.$toasts.error(
          i18n.global.t("toast.error_general")
        );
      } else if (err.message.includes("401")) {
        await handleSessionExpiry();
      } else {
        app.config.globalProperties.$toasts.error(err.message);
      }
    };

    const clipboardCopy = async (field: string, value: string) => {
      if (value && field) {
        navigator.clipboard.writeText(value);
        app.config.globalProperties.$toasts.info(i18n.global.t("toast.clipboard_copy", { field }));
      }
    };

    app.use(authInstaller);
    app.use(store);
    app.use(router);
    app.use(i18n);
    app.use(VuesticPlugin, vuesticGlobalConfig);
    app.use(toasts).provide("toasts", app.config.globalProperties.$toasts);
    app.provide("clipboardCopy", clipboardCopy);
    app.provide("handleError", handleError);

    // This file should be converted to js
    // eslint-disable-next-line
    // @ts-ignore
    store.handleError = handleError

    app.mount("#app");
  })
  .catch(alert);
